import React, { useEffect } from 'react';
import * as Yup from 'yup';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
// redux
import { useDispatch, useSelector } from '../../../../redux/store';
import { createEditBranch, getCities, updateVenueAdvanceDetails } from '../VenuesSlice';
// components
import { FormProvider, RHFTextField, RHFSelect, RHFSwitch0 } from '../../../../components/hook-form';
// hooks
import useLocales from '../../../../hooks/useLocales';
import useResponsive from '../../../../hooks/useResponsive';
import PhoneInputWrapper from '../../../../components/PhoneInputWrapper';
import { use } from 'i18next';
import useAuth from '../../../../hooks/useAuth';
import { useParams } from 'react-router-dom';
import {capitalization} from "../../../../utils/helpers";

// ----------------------------------------------------------------------

const COORDINATE_REGEX = /^-?([1-8]?[0-9]|90)\.\d{1,16}$/;
const LAT_COORDINATE_REGEX = /^-?(90|[1-8]?\d)\.\d{1,16}$/;
const LNG_COORDINATE_REGEX = /^-?(180|1[0-7]\d|\d{1,2})\.\d{1,16}$/;
// const WEB_URL_REGEX = /(https?:\/\/)?([\w-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/;
const WEB_URL_REGEX = /^(https?:\/\/)?(www\.)?([\w-]+)\.([a-zA-Z]{2,63})(\.[a-zA-Z]{2,63})?([\/\w-]*)*\/?(\?[^\s#]*)?(#[^\s]*)?$/;
const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/
// ----------------------------------------------------------------------
Yup.addMethod(Yup.string, "validatePhone", function (errorMessage) {
  return this.test(`validate-phone-number`, errorMessage, (value) => {
    const { path, createError } = this;
    return (
      isValidPhoneNumber(value) ||
      createError({ path, message: errorMessage })
    );
  });
});
// ----------------------------------------------------------------------

export default function BranchGeneral() {
  const isDesktop = useResponsive('up', 'sm');
  const dispatch = useDispatch();
  const theme = useTheme();
  const { org_id = '', venue_id = '' } = useParams();
  const { translate: t } = useLocales();
  const { general } = useSelector((state) => state.venueOnboardEdit.branch);
  const { user } = useAuth();
  const { coworkInfoOptions, venueId, countries, cities, currencies } = useSelector((state) => state.venueOnboardEdit);

  const UpdateBranchSchema = Yup.object().shape({
    name: Yup.string().required(t('form.error-name')),
    phone_number:Yup.string().test('is-valid-phone', 'Phone number is not valid', value => {
      // Ensure the value is not empty and is a valid phone number
      return value ? isValidPhoneNumber(value) : false;
    }).required(t('form.error-mobile')),
    primary_contact_email: Yup.string().email(t('venueCreateEdit.error-emailGeneral')),

    latitude: Yup.string()
      .required(t('venueCreateEdit.error-latitude'))
      .matches(LAT_COORDINATE_REGEX, t('venueCreateEdit.error-validLatitude')),
    longitude: Yup.string()
      .required(t('venueCreateEdit.error-longitude'))
      .matches(LNG_COORDINATE_REGEX, t('venueCreateEdit.error-validLongitude')),
    country: Yup.string().required(t('form.error-country')),
    city: Yup.string().required(t('form.error-country')),
    website_url: Yup.string().required(t('venueCreateEdit.error-website')).matches(WEB_URL_REGEX, t('form.error-url')),
    address: Yup.string().required(t('venueCreateEdit.error-address')),
  });


  const defaultValues = {
    phone_number:general.phone_number,
    ...general,
  };

  const methods = useForm({
    resolver: yupResolver(UpdateBranchSchema),
    defaultValues,
  });

  const {
    watch,
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      delete data.country
      const newBranch = {
        ...data,
        ...getValues(),
      };
      if (!newBranch.is_cowork) newBranch.is_cowork = false;
      if (!newBranch.is_meeting_room) newBranch.is_meeting_room = false;
      if (!newBranch.is_office_room) newBranch.is_office_room = false;
      if (!newBranch.is_creative_studio) newBranch.is_creative_studio = false;
      if (!newBranch.phone_number) newBranch.phone_number = "";
      newBranch.venue_type = (general && general.venue_type) || "";

      const postData = {
        ...(newBranch?.id && {
          // id: newBranch?.id,
          branch_id: newBranch?.id,
        }),
        general: newBranch,
      };

      if (newBranch?.id && venueId) {
        dispatch(updateVenueAdvanceDetails({ general: newBranch }, 'general', venueId, org_id));
      } else {
        dispatch(createEditBranch(postData));
      }
    } catch (error) {
      console.error(error);
    }
  };

  watch();

  useEffect(() => {
    const newDefaultValues = { ...general };
    console.log('resetting form vals...', JSON.stringify(newDefaultValues, null, 2));
    reset(newDefaultValues);
  }, [general, reset]);

  useEffect(() => {
    const newDefaultValues = { ...general };
    if (newDefaultValues.country) {
      dispatch(getCities(newDefaultValues.country)); // TODO: separate to new venue only scenario
    }
  }, [general.country]);

  const onGetCities = () => {
    if (getValues().country) {
      dispatch(getCities(getValues().country)); // TODO: separate to new venue only scenario
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ pb: 4 }}>
            <Typography variant="h4" align="left">
              Tell us about your venue
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'grid',
              rowGap: 3,
              columnGap: 2,
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
            }}
          >
            <RHFTextField name="name" label={t("global.name")} />

            {/* <Controller
              name="phone_number"
              control={control}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <PhoneInputWrapper
                  label="Mobile"
                  className={error && 'input-error white' || 'white'}
                >
                  <span className="mobile-label">{t('form.mobile')}</span>
                  <PhoneInput
                    id="phone_number"
                    defaultCountry="PK"
                    onChange={onChange}
                  />
                  {
                    error && error.message && (
                      <Typography variant="caption" color="error.main" sx={{ mt: 1, ml: 2 }} paragraph>
                        {error.message}
                      </Typography>
                    ) || ''
                  }
                </PhoneInputWrapper>
              )}
            /> */}
            <Controller
                name='phone_number'
                control={control}
                type={'phone'}
                render={({ field: {  value, onChange }, fieldState: { error } }) => (
                    <PhoneInputWrapper
                        label='Mobile'
                        className={error ? 'input-error gradientbg' : 'gradientbg'}
                    >
                      <span className='mobile-label'>{t('form.mobile')}</span>
                      <PhoneInput
                          id='phone_number'
                          value={value}
                          defaultCountry='AE'
                          onChange={onChange}
                      />
                      {error && (
                          <Typography variant='caption' color='error.main' sx={{ mt: 1, ml: 2 }} paragraph>
                            {error.message}
                          </Typography>
                      )}
                    </PhoneInputWrapper>
                )}
            />
            <RHFTextField name="primary_contact_email" label={t("venueCreateEdit.form-emailGeneral")} />
            <RHFTextField name="manager_name" label={t("venueCreateEdit.form-manager")} />
            <RHFTextField name="latitude" label={t("venueCreateEdit.form-latitude")} {...(defaultValues.latitude && { disabled: false } || {})} />
            <RHFTextField name="longitude" label={t("venueCreateEdit.form-longitude")} {...(defaultValues.longitude && { disabled: false } || {})} />
            <RHFSelect name="venue_type" label="Venue Type" sx={{ mb: 2 }} disabled={true}>
              <option value="" />
              {coworkInfoOptions &&
              coworkInfoOptions.venue_types.map(({ label, value }) => (
                  <option key={value} value={value}>{label}</option>
              ))}
            </RHFSelect>
            <RHFSelect name="country" onChange={onGetCities} label={t("form.country")} placeholder={t("form.placeholder-country")} InputLabelProps={{ shrink: true }}>
              <option disabled value="" />
              {countries && countries.map(({ id, name }) => (
                <option key={id} value={id}>{name}</option>
              ))}
            </RHFSelect>
            {getValues().country && (
              <RHFSelect name="city" label={t("venueCreateEdit.form-city")} placeholder={t("venueCreateEdit.placeholder-city")} InputLabelProps={{ shrink: true }}>
                <option disabled value="" />
                {cities && cities?.map(({ id, name }) => (
                  <option key={id} value={id}>{name}</option>
                ))}
              </RHFSelect>
            )}
            <RHFSelect name="currency_code" label={t('Currency')} sx={{ mb: 2 }}>
              <option disabled value="" />
              {currencies.map(({label,value}, index) => (
                <option key={`currency${index}`} value={value}>
                  {label}
                </option>
              ))}
            </RHFSelect>
            <RHFTextField name="address" multiline rows={4} label={t("venueCreateEdit.form-address")} />
            <RHFTextField name="website_url" label={t("venueCreateEdit.form-website")} />
          </Box>
          <Stack
            spacing={isDesktop ? 3 : 0}
            direction={isDesktop ? 'row' : 'column'}
            alignItems={isDesktop ? 'center' : 'flex-start'}
            justifyContent="center"
            sx={{ my: 3, width: '100%' }}
          >
            <RHFSwitch0 name="is_meeting_room" labelPlacement="start" label={t("venueCreateEdit.form-room")} {...(defaultValues.country && { disabled: false } || {})} />
            <RHFSwitch0 name="is_office_room" labelPlacement="start" label={t("venueCreateEdit.form-office")} {...(defaultValues.country && { disabled: false } || {})} />
            <RHFSwitch0 name="is_creative_studio" labelPlacement="start" label={t("venueCreateEdit.form-creative")} {...(defaultValues.country && { disabled: false } || {})} />
            <RHFSwitch0 name="is_cowork" labelPlacement="start" label={t("venueCreateEdit.form-workspace")} {...(defaultValues.country && { disabled: false } || {})} />
          </Stack>
          <RHFTextField name="description" multiline rows={4} label={t("form.description")} />
          <Stack spacing={3} alignItems="flex-start" sx={{ mt: 3 }}>
            <LoadingButton type="submit" size="large" variant="contained" loading={isSubmitting}>
              {t('global.btn-saveChanges')}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
