import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Typography, Button, Box, Card, CardContent } from '@mui/material';
// hooks
//
import Iconify from './Iconify';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
import useAuth from "../hooks/useAuth";

// ----------------------------------------------------------------------
const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  backgroundColor: theme.palette.background.info,
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));


// ----------------------------------------------------------------------

export default function PageVenueNotFound({error =null}) {
  const theme = useTheme();
  const { user } = useAuth();
  const ERROR_DARK = theme.palette.text.info;
  return (
    <RootStyle>
      <CardContent
        sx={{
          p: { md: 3 },
          pl: { md: 5 },
          color: 'grey.800',
        }}
      >
        <Typography gutterBottom variant="h6" color={ERROR_DARK} sx={{ alignItems: 'center', display: 'flex', pr: 5}}>
          <Iconify icon={'bx:error'} sx={{ mr: 1 }} />
          {error ? <span>Failed to load venue listings. Please try again later.</span> :<span>No Venues Added</span>}
        </Typography>
        {error ? <Typography gutterBottom variant="body1" color={ERROR_DARK} sx={{py: 2}}>
            There was an issue fetching the venue data. Please try again later. If the issue persists,
            <br/> please contact the administrator for assistance.
        </Typography> :<>
          <Typography gutterBottom variant="body1" color={ERROR_DARK} sx={{py: 2}}>
            Please add your venues or associate with your organization to proceed. <br/> For assistance, contact support.
          </Typography>
          <Button size="large" variant="contained"
                  to={PATH_DASHBOARD.onboarding.replace(':org_id', user?.organisation_user_details?.organisation?.id)}
                  component={RouterLink}>
            Get started
          </Button>
        </>}

      </CardContent>
    </RootStyle>
  );
}
