import React, {useEffect, useState} from 'react';
import {
    Grid,
    Box,
    Card,
    Radio,
    Avatar,
    Typography,
    Chip,
    TableCell,
    TableBody,
    TableRow,
    Table,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    Container,
    FormControl
} from '@mui/material';
import useLocales from '../../../hooks/useLocales';
import Page from "../../../components/Page";
import useSettings from "../../../hooks/useSettings";
import {ROLE} from "../../../utils/constants";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../utils/axios";
import {URL_CUSTOMERS, URL_PLACES, URL_SETTINGS} from "../../../utils/restApiUrls";
import {EmailInput} from "../venues/venueforms";
import {Cancel, CheckCircle} from '@mui/icons-material';
import {fDateShort} from "../../../utils/formatTime";
import BranchSpaceFilter from "../calendar/sections/BranchSpaceFilter";
import {LoadingButton} from "@mui/lab";
import CustomLocalModal from "../../../components/modal/customLocalModal";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router";

const CUSTOMER_BOX_WIDTH_DESKTOP = '150px';
const CUSTOMER_BOX_WIDTH_MOBILE = '70px';

export default function CustomerCheckIn() {
    const {themeStretch} = useSettings();
    const {user} = useAuth();
    const navigate = useNavigate();
    const {Translate, translate: t} = useLocales();
    const [organisations, setOrganisations] = useState([]);
    const [venues, setVenues] = useState([]);
    const [coworkId, setCoworkId] = useState(null);
    const [isMemberInfoLoading, setIsMemberInfoLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
    const [memberInfo, setMemberInfo] = useState(null);
    const [teamLookup, setTeamLookup] = useState(0);
    const [selectedVenue, setSelectedVenue] = useState(null);
    const showOrgList = user?.system_user === ROLE.SUPER_USER || user?.system_user === ROLE.CUSTOMER_SUPPORT

    const [error, setError] = useState(''); // New state to hold the input value


    const getSpaces = async (org_id, org_update = false) => {
        try {
            const {data: responseData} = await axios.get(URL_PLACES.adminVenues);
            if (responseData.success) {
                const venues = responseData.data
                    ?.filter(item => item.cowork_id != null)
                    .map(item => ({id: item.cowork_id, name: item.name, is_active: item?.is_publish})) || [];

                if (venues.length > 0) {
                    const firstVenueId = venues[0].id;
                    if (org_update || !coworkId) {
                        setCoworkId(firstVenueId);
                    }
                    setVenues(venues);
                }
            }
        } catch (error) {
            console.error("Error fetching spaces:", error);
        }
    };


    const handleVenueChange = (value) => {
        let venue_id = value
        setCoworkId(venue_id)
        if(venue_id && venues?.length){
            venues.find((_branch) =>{
                console.log(_branch,"_branch")
                if( _branch.id === venue_id ){

                    setSelectedVenue(_branch) ;
                }
            });
        }
    }

    const handleTeamDetailChange = (event) => {
        setTeamLookup(event.target.value);
        console.log("Selected value:", event.target.value); // Log the selected value
    };

    useEffect(() => {
        getSpaces(user?.organisation_user_details?.organisation?.id);

    }, [])

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };


    const handleSearch = async ({email}) => {
        if (!validateEmail(email)) {
            setError(t('Invalid email address'));
            return;
        }

        try {
            setError('')
            setMemberInfo(null)
            setIsMemberInfoLoading(true);
            // Simulating API call
            const baseUrl = URL_CUSTOMERS?.userDetails; // Ensure this contains your base URL
            const url = `${baseUrl}?email=${email}&team_lookup=${teamLookup}`;
            console.log(encodeURIComponent(url),"url")
            const response = await axios.get(encodeURIComponent(url));
            console.log(response.data, "res")

            if (response.data.success) {
                setMemberInfo(response.data.data);

            } else {
                setError(response.data.message)
            }

        } catch (error) {
            setError(error?.response?.data.message)
            console.error('Error fetching teams:', error?.response);
        } finally {
            setIsMemberInfoLoading(false);
        }
    }

    const handleModalWarningClose = () => {
        setIsOpenWarningModal(false)
    }

    const handleCheckIn = async () => {
        // const currentValues = getValues('venue_ids');
        // const updatedValues = currentValues.filter((teamId) => teamId !== id);
        try {
             setIsLoading(true)
            // Simulating API call
            if (!memberInfo || !coworkId){
                enqueueSnackbar("Something went wrong, Please try again!", { variant: 'error', preventDuplicate: false });
                return
            }
            let customer_id = memberInfo?.customer_id;
            let data = {
                "cowork_id": coworkId,
                "is_team_checkin": teamLookup == 1 ? true : false ,
                "guests": []
            };
            const response = await axios.post(URL_CUSTOMERS?.userCheckIn.replace(":customer_user_id",customer_id),data);
            if (response.data.success) {
                navigate('/customers/coworking?tab=Active-CheckIns')
                enqueueSnackbar(response.data.message, { variant: 'success' });

            }

        } catch (error) {
            enqueueSnackbar(error?.response?.data.message, { variant: 'error', preventDuplicate: false });
            console.error('Error fetching teams:', error?.response);
        }finally {
            setIsLoading(false)
            handleModalWarningClose()
        }

    };

    return (
        <Page title={t('customers.reviews')}>
            <Container maxWidth={themeStretch ? false : 'xl'}>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="h4" sx={{mb: 5, display: 'block'}}>
                        <Translate i18nKey="customers.checkin"/>
                    </Typography>

                    {/*    <Box sx={{ display: 'flex', justifyContent: 'space-between',gap:'10px' }}>*/}

                    {/*    <BranchSpaceFilter options={venues?.length ? venues : []} label={'Select Venue'}  minWidth={'250px'} type={'organization'} init={coworkId} onChange={handleVenueChange} />*/}
                    {/*</Box>*/}
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card sx={{p: 3, minHeight: '223.7px'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" sx={{color: 'text.secondary', mb: 0}}>
                                        User Details
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={12} lg={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <EmailInput key="2" onSubmit={handleSearch} label={"Add user email"}
                                                        placeholder={"team@letswork.io"} loader={isMemberInfoLoading}/>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={12} lg={12}>
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Fetch as</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={teamLookup}
                                            onChange={handleTeamDetailChange}
                                        >
                                            <FormControlLabel value={0} control={<Radio/>} label="Indiviual"/>
                                            <FormControlLabel value={1} control={<Radio/>} label="Team"/>

                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                {/*{ memberInfo ?*/}
                                {/*    <Grid item  xs={12} >*/}
                                {/*        <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>*/}
                                {/*            Result found*/}
                                {/*        </Typography>*/}
                                {/*    </Grid> : null}*/}

                                {error ?
                                    <Grid item xs={6}>
                                        <Card sx={{p: 3}}>
                                            <Typography variant="subtitle2" sx={{
                                                color: 'text.secondary',
                                                textAlign: 'center'
                                            }}>{error}</Typography>
                                        </Card>
                                    </Grid>
                                    : null
                                }

                                {memberInfo ?
                                    <>
                                        <Grid item xs={12}>
                                            <Card variant="outlined" sx={{
                                                p: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                position: 'relative',
                                                cursor: 'pointer',
                                                boxShadow: 'none',
                                            }}>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow>
                                                            {/* Left Column */}
                                                            <TableCell sx={{ verticalAlign: 'top' }}>
                                                                <Table>
                                                                    <TableBody>
                                                                        <TableRow className="row-style">
                                                                            <TableCell variant="head">Full Name</TableCell>
                                                                            <TableCell>{memberInfo?.customer_name}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="row-style">
                                                                            <TableCell variant="head">Email</TableCell>
                                                                            <TableCell>{memberInfo?.customer_email}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="row-style">
                                                                            <TableCell variant="head">Phone Number</TableCell>
                                                                            <TableCell>{memberInfo?.customer_phone_number}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className="row-style">
                                                                            <TableCell variant="head">Preferred Currency</TableCell>
                                                                            <TableCell sx={{
                                                                                display: 'flex',
                                                                                gap: '5px',
                                                                                alignItems: 'center',
                                                                            }}>
                                                                                <Avatar
                                                                                    alt={memberInfo?.preferred_currency?.code}
                                                                                    src={memberInfo?.preferred_currency?.icon_url}
                                                                                    sx={{
                                                                                        width: 24,
                                                                                        height: 24,
                                                                                        '& img': {
                                                                                            objectFit: 'contain',
                                                                                        },
                                                                                    }}
                                                                                    variant="square"
                                                                                />
                                                                                {`${memberInfo?.preferred_currency?.name} (${memberInfo?.preferred_currency?.code})`}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableCell>
                                                            {/* Right Column */}
                                                            <TableCell sx={{ verticalAlign: 'top' }}>
                                                                <Table>
                                                                    <TableBody>
                                                                        {teamLookup == 1 && memberInfo?.legacy_data?.team_name ?
                                                                        <>
                                                                            <TableRow className="row-style">
                                                                                <TableCell variant="head">Team Name</TableCell>
                                                                                <TableCell>{memberInfo?.legacy_data?.team_name}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="row-style">
                                                                                <TableCell variant="head">Team Member Credit</TableCell>
                                                                                <TableCell>{memberInfo?.legacy_data?.team_member_credit}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="row-style">
                                                                                <TableCell variant="head">Team Owner</TableCell>
                                                                                <TableCell>
                                                                                    <Avatar
                                                                                        children={<Cancel />}
                                                                                        sx={{
                                                                                            width: 24,
                                                                                            height: 24,
                                                                                            bgcolor: memberInfo?.legacy_data?.is_team_owner ? 'green' : 'red',
                                                                                            color: 'white',
                                                                                        }}
                                                                                    />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </> : null
                                                                        }
                                                                        <TableRow className="row-style">
                                                                            <TableCell variant="head">Total Credits Left</TableCell>
                                                                            <TableCell>{memberInfo?.legacy_data?.total_credits_left}</TableCell>
                                                                        </TableRow>
                                                                        {memberInfo?.legacy_data?.active_plan ?
                                                                            <>
                                                                                <TableRow className="row-style">
                                                                                    <TableCell variant="head">Active Plan</TableCell>
                                                                                    <TableCell>{memberInfo?.legacy_data?.active_plan?.name}</TableCell>
                                                                                </TableRow>
                                                                                <TableRow className="row-style">
                                                                                    <TableCell variant="head">Plan Start Date</TableCell>
                                                                                    <TableCell>{fDateShort(memberInfo?.legacy_data?.active_plan?.start_date)}</TableCell>
                                                                                </TableRow>
                                                                                <TableRow className="row-style">
                                                                                    <TableCell variant="head">Plan End Date</TableCell>
                                                                                    <TableCell>{fDateShort(memberInfo?.legacy_data?.active_plan?.end_date)}</TableCell>
                                                                                </TableRow>
                                                                            </>
                                                                            :
                                                                            <TableRow className="row-style">
                                                                                <TableCell variant="head">Active Plan</TableCell>
                                                                                <TableCell>N/A</TableCell>
                                                                            </TableRow>
                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} md={6} lg={6}>
                                                    <BranchSpaceFilter options={venues?.length ? venues : []}
                                                                       label={'Select Venue'} minWidth={'250px'}
                                                                       init={coworkId} onChange={handleVenueChange}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} md={6} lg={6}>
                                                    <LoadingButton variant={"contained"} onClick={()=>{setIsOpenWarningModal(true)}}>
                                                       Check In
                                                    </LoadingButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </>
                                    : null}


                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <CustomLocalModal modalOpen={isOpenWarningModal} handleClose={handleModalWarningClose} modalLoading={isLoading} handleSubmit={handleCheckIn} title={"Please Confirm!"} description={`Are you sure you want to check in the user "${memberInfo?.customer_name}" to the venue "${selectedVenue?.name}"?`} text={"Check In"}/>

            </Container>
        </Page>

    );
}

