import { useSnackbar } from 'notistack';
import { useState} from 'react';
import { /* Link as RouterLink, */ useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, {  tooltipClasses } from '@mui/material/Tooltip';
// routes
import { /* PATH_DASHBOARD, */ PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useSettings from '../../../hooks/useSettings';
import useLocales from '../../../hooks/useLocales';
// redux
import { useDispatch } from '../../../redux/store';
import { logoutResetReduxStates } from '../../../pages/settings/settingsSlice';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------
/*
const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    // linkTo: PATH_DASHBOARD.user.profile,
  },
  {
    label: 'Settings',
    // linkTo: PATH_DASHBOARD.user.account,
  },
];
*/
// ----------------------------------------------------------------------

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: '#637381',
        boxShadow: theme.shadows[1],
        fontSize: theme.typography.pxToRem(12),
        fontWeight:400,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
}));



export default function AccountPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, logout} = useAuth();

  const { Translate, translate: t } = useLocales();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };


  const handleLogout = async () => {
    try {
      dispatch(logoutResetReduxStates('LOGOUT'));
      await logout();
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('header.error.logout'), { variant: 'error' });
    }
  };

  return (
    <>

        <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
            position: 'relative',
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.1),
            },
          }),
        }}
      >
            <LightTooltip
                title={
                    user?.first_name
                        ? ""
                        : "Please complete your profile by adding your name and phone number!"
                }
                arrow
            >
                <Box sx={{ position: "relative", display: "inline-block" }}>
                    <MyAvatar />
                    {!open && !user?.first_name ? (
                        <Box
                            sx={{
                                width: 10,
                                height: 10,
                                bgcolor: "#8C51F5",
                                borderRadius: "50%",
                                position: "absolute",
                                top: 2,
                                right: 2,
                                border: "2px solid white",
                            }}
                        />
                    ) : null}
                </Box>
            </LightTooltip>

        </IconButtonAnimate>


      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email || user?.user_type_display}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        {
          /*
          <Stack sx={{ p: 1 }}>
            {MENU_OPTIONS.map((option) => (
              <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
                {option.label}
              </MenuItem>
            ))}
          </Stack>

          <Divider sx={{ borderStyle: 'dashed' }} />
          */
        }


        <MenuItem
          onClick={() => {
              navigate('profile')
        }}
          sx={{ m: 1 }}>

          <Translate i18nKey='header.profile' />
            {!user?.first_name ? <Box
                sx={{
                    width: 10,
                    height: 10,
                    bgcolor: '#8C51F5',
                    marginLeft: '10px',
                    borderRadius: '50%',
                    border: '2px solid white', // Optional: border to create separation from the avatar
                }}
            /> : null}
        </MenuItem>
        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          <Translate i18nKey='header.logout' />
        </MenuItem>
      </MenuPopover>
    </>
  );
}
