import { LoadingButton } from '@mui/lab';
import useLocales from "../../hooks/useLocales";

export default function CheckoutButton(props) {
    const { Translate} = useLocales();
  return (
    <LoadingButton variant={props.variant || "contained"} {...props}>
        <Translate i18nKey={ props?.title || 'global.checkOut'} />
    </LoadingButton>
  );
}
